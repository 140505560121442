@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #ffffff;
}
.background {
  background: #f6f9fc;
  padding: 1.4rem 0;
}
.container {
  max-width: 90%;
  margin: auto;
}
.d_flex {
  display: flex;
  /* justify-content: space-between; */
  /* justify-content: space-around; */
}
.c_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.f_flex {
  display: flex;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.RText {
  text-align-last: right;
}
input,
button {
  border: none;
  outline: none;
  background-color: none;
}
.row {
  width: 50%;
}
.icon-circle {
  background: #f3f5f9;
  margin-left: 1.3rem;
  text-align: center;
  border-radius: 50%;
}
.boxShadow {
  box-shadow: rgb(43 52 69 / 10%) 0rem 0.3rem 1rem;
}

/*---------category----------*/
.contentWidth {
  width: 77%;
}
.category {
  box-shadow: rgb(43 52 69 / 10%) 0rem 0.3rem 1rem;
  background-color: #fff;
  width: 23%;
  height: 28rem;
  padding: 0.9rem 0;
  border-radius: 0.3rem;
  margin-top: 0.3rem;
}
.category img {
  width: 1.9rem;
  height: 1.9rem;
  margin-top: 0.6rem;
  object-fit: contain;
}
.category span {
  margin: 0.6rem 1.3rem;
  text-transform: capitalize;
}
.category .box {
  transition: 0.5s;
  padding: 0 1.3rem;
}
.category .box:hover {
  background-color: #ffe1e6;
  cursor: pointer;
}
.category-images {
  width: 2.5rem;
  height: 2.5rem;
}
/*---------category----------*/
.btn-primary {
  background: #e94560;
  padding: 0.8rem 2.5rem;
  font-weight: bold;
  color: #fff;
  border-radius: 0.3rem;
  margin-left: 0.1rem;
  cursor: pointer;
  z-index: 999999;
}
.top {
  margin-top: 5rem;
}
.mtop {
  margin-top: 2.5rem;
}
.heading i {
  color: #e94560;
  margin: 0.6rem;
}
.discount {
  position: absolute;
  top: 0;
  left: 0;
  background: #e94560;
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  border-radius: 3.1rem;
  color: #fff;
  margin: 0.6rem;
}
/*---------product-----------*/
.product {
  background: #fff;
  padding: 1.3rem;
  position: relative;
  border-radius: 0.5rem;
  box-shadow: rgb(3 0 71 / 9%) 0rem 0.1rem 0.2rem;
  margin: 0.6rem;
  min-height: 23.8rem;
  max-height: 23.8rem;
}
.product2 {
  background: #fff;
  padding: 1.3rem;
  position: relative;
  border-radius: 0.5rem;
  box-shadow: rgb(3 0 71 / 9%) 0rem 0.1rem 0.2rem;
  margin: 0.6rem;
}
.product h3 {
  font-weight: 400;
  font-size: 1.1rem;
}
.product .rate i {
  font-size: 0.9rem;
  color: #ffcd4e;
  margin: 0.3rem 0.3rem 0.3rem 0;
}
.product .price {
  display: flex;
  justify-content: space-between;
  color: #e94560;
}
.price button {
  background: none;
  color: #e94560;
  font-size: 1.3rem;
  transition: 0.5s;
  border: 0.1rem solid rgb(3 0 71 / 9%);
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 0.3rem;
}
.price button:hover {
  cursor: pointer;
  background-color: #e94560;
  color: #fff;
}
.product-like {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.6rem;
  transition: 0.5s;
}
.product-like label {
  background-color: #0f3460;
  padding: 0.1rem 0.6rem;
  font-size: 0.8rem;
  color: white;
  border-radius: 3.1rem;
}
.product-like i {
  font-size: 1.3rem;
  margin: 0.6rem 0.2rem;
}

/*---------product-----------*/
/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 3.1rem;
  height: 3.1rem;
  line-height: 3.4rem;
  background: #0f3460;
  border-radius: 50%;
  transform: 0.5s;
  z-index: 5;
}
.control-btn .prev {
  left: -1.3rem;
}
.control-btn .next {
  right: -1.3rem;
}
.control-btn .prev i,
.control-btn .next i {
  font-size: 1.3rem;
  transition: 0.5s;
  color: #fff;
}
.control-btn .prev:hover,
.control-btn .next:hover {
  background: #e94560;
  color: white;
  cursor: pointer;
}
/*----- control btn -------*/
/*----- heading -------*/
.heading .heading-left i {
  font-size: 1.9rem;
}
.heading .heading-left h2 {
  font-size: 1.6rem;
  margin: 0.3rem;
}
.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: grey;
}
/*----- heading -------*/
.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1.9rem;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.6rem;
}
.smallest-container {
  display: flex;
}

/* additions */
.cat-images-display {
  width: 100%;
}

@media only screen and (max-width: 47.9rem) {
  .small-screen-display-none {
    display: none;
  }
  .small-screen-display-true {
    display: block;
  }
  .heading .heading-left h2 {
    font-size: 1.4rem;
  }
  .icon-circle {
    margin-right: 2.5rem;
  }
  .control-btn .prev,
  .control-btn .next {
    background: rgb(3 0 71 / 9%);
  }
  .control-btn .prev:hover,
  .control-btn .next:hover {
    background: #e94560b3;
  }
  .contentWidth {
    width: 100%;
  }
}
/* 359 screen */
@media only screen and (max-width: 24rem) {
  .heading .heading-left h2 {
    font-size: 0.9rem;
  }
  .product h3 {
    font-size: 0.9rem;
  }
  .product {
    padding: 0.5rem;
    width: 93%;
    min-height: 17.9rem;
    max-height: 17.9rem;
    min-width: 7.5rem;
  }
  .price button {
    font-size: 1rem;
    height: 1.8rem;
  }
  .smallest-container {
    display: flex;
    margin-left: 10px;
  }
  .cat-images-display {
    width: 93%;
  }
  .btn-primary {
    padding: 0.3rem 2.5rem;
  }
  .discount {
    top: -5px;
    left: -4px;
    background: #de4d65;
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
  }
  .product-like {
    top: -5px;
    right: 0;
    margin: 0.2rem;
  }
  .control-btn .prev,
  .control-btn .next {
    background: rgb(3 0 71 / 9%);
  }
  .control-btn .prev:hover,
  .control-btn .next:hover {
    background: #e94560b3;
  }
  .heading .heading-left i {
    font-size: 1.5rem;
  }
  .product2 {
    padding: 0.3rem;
  }
  .contentWidth {
    width: 100%;
  }
}
