.homeSlide h1 {
  font-size: 2.8rem;
  line-height: 3.4rem;
  margin: 3.1rem 0 1.3rem 0;
}
.homeSlide p {
  margin: 1.3rem 0;
}
.homeSlide ul {
  position: absolute;
  bottom: -1.3rem;
}
.homeSlide ul li {
  border: 0.1rem solid #b8b7b7;
  border-radius: 50%;
  padding: 0.6rem;
}
.homeSlide ul li button {
  background: transparent !important;
  color: transparent !important;
}
.homeSlide ul li button::before {
  font-size: 0.9rem !important;
  color: #0f3460;
  position: absolute;
  top: 0.1rem;
  left: 0;
  margin: auto;
}
.home-contentWidth {
  width: 77%;
}
.home-top {
  margin-top: 5rem;
}
.home-container {
  max-width: 90%;
  margin: auto;
}
.home-image {
  max-width: 18.8rem;
  max-height: 18.8rem;
}
.category-images {
  width: 1.9rem;
  height: 1.9rem;
}
@media only screen and (max-width: 47.9rem) {
  .home-contentWidth {
    width: 100%;
  }
  .homeSlide h1 {
    font-size: 1.3rem;
    line-height: 1.6rem;
    margin: 0.1rem 0 0.6rem 0;
  }
  .home-top {
    margin-top: 1.9rem;
  }
  .home-container {
    max-width: 100%;
  }
  .homeSlide ul {
    bottom: -2.5rem;
    padding: 0.1rem;
  }
  .home-image {
    max-width: 20.5rem;
    max-height: 20.5rem;
  }
}
@media only screen and (max-width: 24rem) {
  .homeSlide h1 {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin: 0 0 0 0;
  }
  .homeSlide p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
  }
  .home-image {
    /* width: 100%; */
    max-width: 10.5rem;
  }
  /* width beyond 100% */
  .home-contentWidth {
    width: 102%;
  }
}
@media only screen and (max-width: 21.3rem) {
  .home-image {
    max-width: 7.5rem;
  }
  .name-wrapper h1 {
    font-size: 25px;
  }
}
