.TopCate {
  position: relative;
}
.TopCate img {
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
}
.TopCate .nametop {
  position: relative;
  width: 100%;
}
.TopCate .nametop span {
  background: #0f3460;
  color: #fff;
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
  border-radius: 3.1rem;
  margin: 0.3rem;
  position: absolute;
  top: 0.6rem;
}
.TopCate .nametop .tleft {
  left: 0;
}
.TopCate .nametop .tright {
  background-color: aliceblue;
  color: black;
  position: absolute;
  right: 0;
}
.max-height {
  max-height: 18.8rem;
  min-height: 18.8rem;
}
.max-height img {
  max-height: 18.8rem;
}
.color-brown {
  color: gray;
}

@media only screen and (max-width: 47.9rem) {
  .TopCate .nametop .tright {
    background-color: rgb(3 0 71 / 9%);
    color: black;
    position: absolute;
    right: 0;
  }
  .max-height {
    max-height: 6.3rem;
    min-height: 6.3rem;
  }
  .TopCate .nametop .tleft {
    top: 4.9rem;
  }
  .max-height img {
    max-height: 6.8rem;
    max-width: 8.9rem;
  }
}
@media only screen and (max-width: 24rem) {
  .max-height img {
    width: 85%;
  }
  .TopCate .nametop .tleft {
    top: 3.9rem;
    font-size: 10px;
  }
}
