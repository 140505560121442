.shop .category {
  padding: 1.9rem;
  height: 90vh;
}
.general-product-card {
  cursor: pointer;
}
.chead h1 {
  font-size: 1.3rem;
  margin-bottom: 1.3rem;
  margin-right: 1.2rem;
}
.chead h1:nth-child(2) {
  opacity: 0.5;
  border-left: 0.2rem solid rgba(0, 0, 0, 0.5);
  padding-left: 1.3rem;
}
.shop .category .box {
  background-color: #f6f9fc;
  margin: 0.9rem 0;
  padding: 0.3rem 0.6rem;
  transition: 0.5s;
  border-radius: 0.3rem;
}
.shop .category img {
  width: 1.3rem;
  height: auto;
}
.shop .category .box:hover {
  background-color: #fff;
  box-shadow: rgb(43 52 69 / 10%) 0rem 0.3rem 1rem;
}
.shop .category .box .active {
  background-color: #007bff;
  color: #ffffff;
}
.shop .box2 {
  margin-top: 6.3rem !important;
  text-align: center;
}
.shop .category button {
  padding: 0.6rem;
  background: none;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
}

.shop .contentWidth {
  margin-left: 1.9rem;
}
.color-brown {
  color: gray;
}

.grid1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.3rem;
}
.product img {
  width: 100%;
  height: 100%;
  max-height: 13.3rem;
}

.shop .product {
  transition: 0.5s;
}
.shop .product:hover {
  box-shadow: rgb(43 52 69 / 10%) 0rem 0.3rem 1rem;
}

@media only screen and (max-width: 47.9rem) {
  .grid1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.6rem;
  }
}
@media only screen and (max-width: 24rem) {
  .shop .contentWidth {
    margin-left: 0rem;
  }
  .product img {
    max-height: 8.4rem;
  }
}
