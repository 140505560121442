.comming-items {
  background: #f6f9fc;
  height: auto;
  padding: 3.1rem 0rem;
}
.comming-body {
  background: #00091b;
  color: #fff;
  min-height: 50vh;
}

@keyframes fadeIn {
  from {
    top: 20%;
    opacity: 0;
  }
  to {
    top: 100;
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    top: 20%;
    opacity: 0;
  }
  to {
    top: 100;
    opacity: 1;
  }
}

.comming-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  animation: fadeIn 1000ms ease;
  -webkit-animation: fadeIn 1000ms ease;
}

.comming-wrapper h1 {
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 700;
}

.comming-wrapper .dot {
  color: #4febfe;
}

.comming-wrapper p {
  text-align: center;
  margin: 18px;
  font-family: "Muli", sans-serif;
  font-weight: normal;
}

.comming-wrapper .icons {
  text-align: center;
  background-color: #fff;
  padding: 15px 0px;
  border-radius: 9px;
}

.comming-wrapper .icons i {
  color: #00091b;
  background: #fff;
  height: 15px;
  width: 15px;
  padding: 13px;
  margin: 0 10px;
  border-radius: 50px;
  border: 2px solid #fff;
  transition: all 200ms ease;
  text-decoration: none;
  position: relative;
}

.comming-wrapper .icons i:hover,
.icons i:active {
  color: #fff;
  background: none;
  cursor: pointer !important;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  text-decoration: none;
}
@media only screen and (max-width: 47.9rem) {
  .comming-wrapper h1 {
    font-size: 36px;
  }
  .comming-wrapper {
    top: 45%;
  }
  .comming-body {
    min-height: 54vh;
  }
}
@media only screen and (max-width: 24rem) {
  .comming-wrapper h1 {
    font-size: 30px;
    text-align: center;
  }
  .comming-wrapper .icons {
    padding: 8px 0px;
  }
  .comming-wrapper {
    top: 40%;
  }
}
