.flash {
  background: #f6f9fc;
  padding: 3.1rem 0;
}
.cursor-pointer {
  cursor: pointer;
}
@media only screen and (max-width: 47.9rem) {
  .flash {
    background: #f6f9fc;
    padding: 3.1rem 0 0.6rem;
  }
}
@media only screen and (max-width: 24rem) {
  .flash {
    background: #f6f9fc;
    padding: 3.1rem 0 0;
  }
}
