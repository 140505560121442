.cart-items {
  background: #f6f9fc;
  height: auto;
  padding: 3.1rem 0rem;
}

.cart-items .cart-details {
  width: 70%;
}

.cart-items .cart-total {
  width: 30%;
  margin-top: 1.9rem;
  margin-left: 1.9rem;
  height: 8.1rem;
}
.cart-list {
  background: white;
  margin-top: 1.9rem;
}
.cart-items .img {
  width: 9.4rem;
  height: 9.4rem;
}
.cart-items img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cart-items .cart-details h3 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1.3rem;
}
.cart-items .cart-details h4 {
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 3.1rem;
  color: grey;
}
.cart-items h4 span {
  color: #e94560;
  margin-left: 1.3rem;
  font-weight: 500;
}
.removeCart {
  background: none;
  font-size: 1.6rem;
  text-align: right;
  margin-right: 0.6rem;
}
.cartControl {
  margin-top: 3.1rem;
}
.cartControl button {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.6rem;
  border-radius: 0.3rem;
  font-size: 1.3rem;
}
.incCart {
  background: none;
  border: 0.1rem solid rgb(3 0 71 / 9%);
  color: #e94560;
}
.desCart {
  background: #f6f9fc;
}
.cart-total h4 {
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0.3rem;
}
.cart-total h3 {
  font-size: 1.3rem;
  font-weight: 500;
  color: #e94560;
}
.cart-total h2 {
  font-size: 1.1rem;
  margin-bottom: 1.3rem;
  border-bottom: 0.1rem solid rgb(3 0 71 / 9%);
  padding-bottom: 0.6rem;
  color: #e94560;
}
.no-items {
  color: #e94560;
  font-size: 1.1rem;
  margin-top: 1.9rem;
  height: 8.1rem;
}

.cart-flex {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 47.9rem) {
  .cart-flex {
    display: block;
  }
  .cart-items .cart-details {
    width: 100%;
  }
  .cart-items .cart-total {
    width: 85%;
  }
  .cart-items .cart-details h3 {
    font-size: 0.9rem;
    margin-top: 0.3rem;
  }
  .cart-items .cart-details h4 {
    margin-top: 1.1rem;
  }
  .removeCart {
    font-size: 1.3rem;
    margin-right: 0;
  }
  .cartControl {
    margin-top: 6.5rem;
  }
  .cartControl button {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 24rem) {
  .cart-items .cart-details h3 {
    font-size: 0.8rem;
  }
  .product img {
    min-width: 3.4rem;
  }
  .removeCart {
    font-size: 1.2rem;
    margin-right: 19px;
  }
  .cart-items .cart-details h4 {
    font-size: 0.8rem;
    margin-top: 0.3rem;
  }
  .cartControl {
    margin-top: 5.3rem;
  }
  .cartControl button {
    margin: 0.12rem;
    font-size: 1rem;
  }
  /* .product {
    min-height: 12rem;
  } */
}
