.Game {
  text-align: center;
  margin: 20px 0;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px;
  justify-content: center;
}

.card {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card.flipped {
  background-color: #ddd;
}
