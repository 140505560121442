.products-items {
  background: #f6f9fc;
  height: auto;
  padding: 3.1rem 0rem;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.pagination span {
  cursor: pointer;
  margin: 0 5px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.pagination span.active {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}
.active-cat {
  background-color: #007bff;
  color: #007bff;
}
.selected-option {
  justify-content: space-between;
}
.selection-option {
  display: flex;
}
.selection-option p {
  text-align: center;
  margin: 9px 9px 0 0;
  font-size: 19px;
}
select {
  appearance: none;
  background-color: #fff;
  border: 2px solid #3498db;
  color: #333;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

select:hover,
select:focus {
  border-color: #207bd9;
}

select::-ms-expand {
  display: none;
}

select::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
}

option:checked {
  background-color: #3498db;
  color: #fff;
}
