.wrapper {
  text-align: center;
}
.wrapper .img {
  margin: auto;
  width: 4.4rem;
  height: 4.4rem;
  line-height: 4.4rem;
  margin-bottom: 1.3rem;
}
.wrapper .img i {
  font-size: 1.6rem;
}
.wrapper .product {
  padding: 1.2rem;
}
.wrapper .product h3 {
  font-weight: 500;
}
.wrapper p {
  font-size: 0.9rem;
  margin-top: 1.3rem;
  color: grey;
}
.display-in-two {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.6rem;
}
.product-wrapper {
  background: #fff;
  padding: 1.3rem;
  position: relative;
  border-radius: 0.5rem;
  box-shadow: rgb(3 0 71 / 9%) 0rem 0.1rem 0.2rem;
  margin: 0.6rem;
  /* min-height: 23.8rem;
  max-height: 23.8rem; */
}

@media only screen and (max-width: 47.9rem) {
  .display-in-two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.6rem;
  }
}
@media only screen and (max-width: 24rem) {
  .display-in-two {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.6rem;
  }
}
