footer {
  background-color: #0f3460;
  padding: 3.1rem 0;
  color: #fff;
}
footer h1 {
  margin-bottom: 1.9rem;
  color: #fff;
  font-weight: 800;
  font-style: italic;
}
footer .img {
  background-color: #0c2a4d;
  padding: 0.9rem 1.3rem;
  border-radius: 0.3rem;
  margin: 0.2rem;
}
footer .img i {
  font-size: 1.3rem;
  margin: 0.3rem;
}
footer .img span {
  margin-top: 0.3rem;
}
footer p {
  font-size: 0.9rem;
  font-weight: 300;
  opacity: 0.5;
  margin-bottom: 1.3rem;
  margin-top: 8px;
}
footer h2 {
  margin-bottom: 1.3rem;
}
footer li {
  margin-bottom: 0.6rem;
  opacity: 0.5;
}
.brand-advert {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: #fff;
  margin: 0;
}

@media only screen and (max-width: 47.9rem) {
  footer {
    padding: 1.3rem 0;
  }
  .footer-box .icon {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 24rem) {
  footer .img {
    padding: 0.7rem 0.6rem;
  }
  footer h2 {
    margin-bottom: 0.7rem;
  }
}
