@import url("https://fonts.googleapis.com/css2?family=Rubik+Bubbles:wght@700&display=swap");

/*------head-----------*/
.head {
  background-color: #0f3460;
  padding: 0.6rem 0;
  color: #fff;
}
.justify-content-areound {
  justify-content: space-between;
}

@media only screen and (max-width: 47.9rem) {
  .head {
    display: none;
  }
}
.head i {
  margin-right: 0.6rem;
}
.head label {
  margin-right: 1.9em;
  font-size: 0.8rem;
  cursor: pointer;
}
/*------head-----------*/
/*------search-----------*/
.search {
  padding: 1.3rem 0;
}
.search .logo img {
  width: auto;
  height: auto;
}
/* .search .width {
  width: 20%;
} */
.search-box {
  width: 80%;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  /*padding: 10rem;*/
  border-radius: 3.1rem;
}
.search-box2 {
  width: 100%;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  /*padding: 10rem;*/
  border-radius: 3.1rem;
  display: none;
}
.search-box i {
  width: 5%;
  text-align: center;
  font-size: 1.3rem;
  opacity: 0.5;
  padding: 0.9rem 1.3rem;
  font-size: 1.1rem;
}
.search-box2 i {
  width: 5%;
  text-align: center;
  font-size: 1.3rem;
  opacity: 0.5;
  padding: 0.9rem 1.3rem;
  font-size: 1.1rem;
}
.search-box input {
  width: 100%;
  padding: 0.6rem;
}
.search-box2 input {
  width: 100%;
  padding: 0.6rem;
}
::placeholder {
  font-size: 0.9rem;
}
.search-box span {
  width: 20%;
  opacity: 0.5;
  border-left: 0.1rem solid rgba(0, 0, 0, 0.1);
  padding: 0.6rem;
}
.search-box2 span {
  width: 20%;
  opacity: 0.5;
  border-left: 0.1rem solid rgba(0, 0, 0, 0.1);
  padding: 0.6rem;
}
.cart {
  position: relative;
}
.search .cart span {
  position: absolute;
  top: -0.6rem;
  right: 0rem;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  text-align: center;
  background-color: #e94560;
  font-size: 0.8rem;
  color: #fff;
}
.search .f_flex {
  justify-content: end;
  margin-right: 10px;
}
/*------search-----------*/
.catgrories {
  background-color: #f6f9fc;
  padding: 0rem 1.9rem;
  border-radius: 0.3rem;
  height: 6vh;
  margin-top: 0.3rem;
}
.catgrories span {
  margin-right: 1.3rem;
  font-size: 1.9rem;
  margin-top: 0.6rem;
}
.catgrories h4 {
  font-weight: 500;
  margin-top: 0.8rem;
}
.catgrories h4 i {
  margin-left: 0.6rem;
}
header {
  height: 8vh;
  box-shadow: -0.1rem 0.9rem 1.1rem -0.5rem rgba(0, 0, 0, 0.1);
}
header ul {
  line-height: 8vh;
}
header ul li {
  margin-left: 1.9rem;
}
header ul li a {
  transition: 0.5s;
  font-size: 0.9rem;
  font-weight: 400;
}
header ul li a:hover {
  color: #ff014f;
}

.open {
  display: none;
}
.search.active {
  height: 12vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: #fff;
  box-shadow: -0.1rem 0.7rem 1.5rem -0.7rem rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

@media (max-width: 47.9rem) {
  header ul {
    padding-top: 1.9rem;
  }
  .nav-links-MobileMenu {
    position: absolute;
    display: block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0rem 3.1rem 6.3rem -1.3rem,
      rgba(0, 0, 0, 0.3) 0rem 1.9rem 3.8rem -1.9rem;
    left: 0;
    top: 0;
    transition: all 0.5s ease-in-out;
    width: 60%;
    height: 100vh;
    background-color: white;
    z-index: 888;
    /* transition: all 0.5s ease-in-out; */
  }
  header ul li {
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
    line-height: 4.3rem;
  }
  header .link {
    display: none;
  }
  .close,
  .open {
    position: absolute;
    top: 2.3rem;
    right: 1.3rem;
    color: #ff014f;
    display: block;
    font-size: 1.6rem;
    z-index: 999;
  }
  .close {
    right: 45%;
  }
  .close.home-btn {
    background: linear-gradient(145deg, #e2e8ec, #ffffff);
    color: #ff014f;
    border-radius: 50%;
    padding: 0;
    width: 3.8rem;
    height: 3.8rem;
    line-height: 3.8rem;
  }
  .header.active {
    height: 10vh;
  }
}
.footer-container {
  max-width: 90%;
  margin: auto;
}
.footer-grid2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.6rem;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
  top: 3.6rem;
  z-index: 99;
  background: #e6dada;
  padding: 1.3rem;
  border-radius: 8px;
  left: 5%;
  cursor: pointer;
}
.popup-box ul li {
  margin: 1.3rem -1px;
  border-bottom: 1px solid #fff;
}
.logo-name {
  display: flex;
  margin: 0 16px 0 0;
  justify-content: center;
}
.name-wrapper h1 {
  font-size: 30px;
  font-family: "Varela Round", sans-serif;
  margin-bottom: 0;
  line-height: 0.7;
  font-weight: 600;
  color: #ff0000b8;
  font-style: italic;
}
.name-wrapper p {
  font-family: "Oswald", sans-serif;
  font-size: 19px;
}
.name-wrapper img {
  text-align: center;
}
.name-wrapper .dot {
  color: #4febfe;
}
.whatapp-icon {
  font-size: 23px;
}
/* Media query for small screens */
@media only screen and (max-width: 47.9rem) {
  .search-box2 {
    display: flex;
  }
  footer {
    padding: 1.3rem 0;
  }
  .footer-grid2 {
    display: block;
  }
  .footer-box {
    text-align: center;
    margin-bottom: 1.3rem;
  }
  .search .cart span {
    right: 2.2rem;
  }
  .search.active {
    height: 10vh;
  }
  .position-absolute {
    top: 9.1rem;
    padding: 0 1.3rem;
    margin: 0 3.1rem;
  }
  .popup-box ul {
    padding-top: 0.5rem;
  }
  .popup-box ul li {
    margin: 0.3rem -1px;
    line-height: 22px;
    font-size: 11px;
    text-transform: capitalize;
  }
  .name-wrapper h1 {
    font-size: 36px;
  }
  .search .width {
    width: 100%;
  }
}
@media only screen and (max-width: 24rem) {
  .search {
    padding: 0.8rem 0;
  }
  .close,
  .open {
    top: 1.7rem;
  }
  .search.active {
    position: fixed;
    top: 0;
  }
  .position-absolute {
    top: 8.1rem;
    margin: 0 1rem;
  }
  .name-wrapper h1 {
    font-size: 23px;
  }
}
