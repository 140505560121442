.prod-details-items {
  background: #f6f9fc;
  height: auto;
  padding: 3.1rem 0rem;
}
.prod-details-container {
  display: flex;
}
.left-image {
  text-align: start;
}
.right-descriptions h1 {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin: 1.1rem 0 1.3rem 0;
}
.right-descriptions p {
  margin: 1.3rem 0;
}

.small-images-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.small-image {
  width: 3.1rem;
  height: auto;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}
.small-image:hover {
  transform: scale(1.1);
}
.small-image.active {
  border: 0.1px solid #eec51f;
  border-radius: 3px;
  padding: 5px;
}
.right-descriptions {
  margin: 0.9rem;
}

.btnprimary2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #0074cc;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.btnprimary2 i {
  margin-right: 5px;
  font-size: 20px;
  transition: transform 0.3s;
}

.btnprimary2:hover {
  background-color: #005aa5;
}

.btnprimary2:hover i {
  transform: scale(1.2);
}

.price {
  color: #e94560;
}
.inquires {
  display: flex;
}
.inquires a {
  margin-right: 5px;
}

/* YourComponent.css */
.fantastic-list {
  list-style-type: none;
  padding: 0;
}

.fantastic-list li {
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #333;
  position: relative;
}

.fantastic-list li::before {
  content: "•";
  color: #ff6600;
  font-size: 1.2em;
  margin-right: 8px;
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 47.9rem) {
  .prod-details-container {
    display: block;
  }
  .left-image {
    text-align: center;
  }
  .right-descriptions h1 {
    font-size: 1.3rem;
    line-height: 1.6rem;
    margin: 0.1rem 0 0.6rem 0;
  }
  .inquires {
    display: block;
    text-align: center;
  }
  .inquires a {
    margin-top: 5px;
  }
  .btnprimary2 {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 24rem) {
  .prod-details-container {
    display: block;
  }
  .left-image {
    text-align: center;
  }
  .right-descriptions h1 {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin: 0 0 0 0;
  }
  .right-descriptions p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
  }
  .btnprimary2 {
    padding: 0.5rem 1rem;
  }
}
